<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">

		<div class="pt-2">
			<router-link :to="{name:'cklb_list_create'}" class="mr-2">
				<el-button type="success" size="mini">新建物料</el-button>
			</router-link>
		</div>

		<el-table border class="mt-3" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" size="mini">
			<el-table-column width="45" align="center" label="编号">
				<template slot-scope="scope">
					<div>{{scope.row.id}}</div>
				</template>
			</el-table-column>
			
			<el-table-column label="物料名称" width="300">
				<template slot-scope="scope">
					<div>{{scope.row.mc}}</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="数量">
				<template slot-scope="scope">
					<div v-if="scope.row.num > scope.row.yj">{{scope.row.num}}</div>
					<div v-else style="background: #cf0003;color: #fff;">{{scope.row.num}}</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="最近拿货价">
				<template slot-scope="scope">
					<div>{{scope.row.nhj}}</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="预警状态">
				<template slot-scope="scope">
					<div style="background: #00cf33;color: #fff;" v-if="scope.row.num > scope.row.yj">库存充足</div>
					<div style="background: #cf0003;color: #fff;" v-else>库存警报</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="更新时间">
				<template slot-scope="scope">
					<div>{{scope.row.update_time}}</div>
				</template>
			</el-table-column>
			
			<el-table-column align="center" label="创建时间" width="120">
				<template slot-scope="scope">
					<div>{{scope.row.create_time.slice(0, 10)}}</div>
				</template>
			</el-table-column>
			
			<!-- 操作 -->
			<el-table-column align="center" label="操作" width="260">
				<template slot-scope="scope">
					<div v-if="tab != 'delete'">
						<el-button type="primary" size="mini" @click="navigate('cklb_list_create',scope.row.id)">物料详情</el-button>
						<el-button type="success" size="mini" @click="printReport(scope.row)">打印保质期</el-button>
						<!-- <el-button type="text" size="mini" >折扣设置</el-button> -->
						<!-- <el-button type="danger" size="mini" @click="deleteItem(scope.row)">删除物料</el-button> -->
					</div>
					<div v-else>暂无操作</div>
				</template>
			</el-table-column>
		</el-table>
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0;left: 200px;right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination :current-page="page.current" :page-sizes="page.sizes" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
				 :total="page.total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</el-footer>

	</div>
</template>

<script>
	import buttonSearch from '@/components/common/button-search.vue'; // 引入 按钮、搜索、高级搜索组件
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	import getLodop from '@/components/getLodop/getLodop.js';
	export default {
		mixins: [common], // 引入mixins里的common.js
		inject: ['layout'], // 依赖注入
		// 引入注册
		components: {
			buttonSearch,
			getLodop
		},
		data() {
			return {
				preUrl: "cklb", // api

				tabIndex: 0,
				tabbars: [
					{name: "全部",key: "all"}
				],
				form: {
					title: "",
					category_id: "" 
				},
				tableData: [],
				cateOptions: [],
				dqsj:'',// 当前时间
				gqsj:''// 过期时间
			}
		},
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		// 计算属性
		computed: {
			// 订单类型
			tab() {
				return this.tabbars[this.tabIndex].key //拿到key值，用来高级搜索
			},
			params() {
				let str = ''
				for (let key in this.form) {
					let val = this.form[key]
					if (val) {
						str += `&${key}=${this.form[key]}`
					}
				}
				return str
			}
		},
		// 生命周期，调用__getData生成的数据
		created() {

		},
		// 生命周期监听页面
		methods: {
			// 调转基础设置、文章规格、文章属性、媒体设置、文章详情页面
			navigate(name, id) {
				this.$router.push({
					name,
					params: { id }
				})
			},
			// 获取请求列表分页url
			getListUrl() {
				return `/admin/${this.preUrl}/${this.page.current}?limit=100&tab=${this.tab}${this.params}`
			},
			// 获取列表 可修改
			getListResult(e) {
				this.tableData = e.list
				this.cateOptions = e.cates
			},
			time(e){
				let nowdate = new Date()
				let y =  nowdate.getFullYear()
				let m = nowdate.getMonth() + 1 // 月
				let r = nowdate.getDate() // 日
				let x = nowdate.getHours() // 时
				let f = nowdate.getMinutes() // 分
				let miao = nowdate.getSeconds() // 秒
				let ms = m
				let rs = r
				let xs = x
				let fs = f
				let miaos = miao
				if(e == 0){
					xs = x + 6
				}else if(e == 1){
					xs = x + 12
				}else if(e == 2){
					rs = r + 1
				}else if(e == 3){
					rs = r + 2
				}else if(e == 4){
					rs = r + 3
				}else if(e == 5){
					rs = r + 15
				}else if(e == 6){
					ms = m + 1
				}else{
					this.gqsj = '当天打烊'
				}
				// 24小时+1天
				if(xs >= 24){
					xs = xs - 24
					rs = r + 1
				}
				// 30天+1月
				if(rs > 30){
					rs = rs - 30
					ms = m + 1
				}
				
				if(x<10){
					x = '0'+x
				}
				if(f<10){
					f = '0'+f
				}
				if(m<10){
					m = '0'+m
				}
				if(r<10){
					r = '0'+r
				}
				if(miao<10){
					miao = '0'+miao
				}
				if(xs<10){
					xs = '0'+xs
				}
				if(fs<10){
					fs = '0'+fs
				}
				if(ms<10){
					ms = '0'+ms
				}
				if(rs<10){
					rs = '0'+rs
				}
				if(miaos<10){
					miaos = '0'+miaos
				}
				this.dqsj = y + '-' + m + '-' + r + ' ' + x + ':' + f + ':' + miao
				this.gqsj = y + '-' + ms + '-' + rs + ' ' + xs + ':' + fs + ':' + miaos
			},
			printReport(e) {
				if(e.bzq == 1){
					this.time(1)
				}else if(e.bzq == 2){
					this.time(2)
				}else if(e.bzq == 3){
					this.time(3)
				}else if(e.bzq == 4){
					this.time(4)
				}else if(e.bzq == 5){
					this.time(5)
				}else if(e.bzq == 6){
					this.time(6)
				}else{
					this.time(7)
				}
				var data = {
					name:e.mc,
					dqsj:this.dqsj,
					gqsj:this.gqsj
				}
				console.log(data)
				const LODOP = getLodop();
				LODOP.SET_PRINT_PAGESIZE(1, '40mm',"40mm","");
				
				LODOP.SET_PRINT_STYLE("FontSize",14);
				LODOP.ADD_PRINT_TEXT('2mm','1mm','RightMargin:5mm','38mm',data.name);
				LODOP.SET_PRINT_STYLE("FontSize",9);
				LODOP.ADD_PRINT_TEXT('14mm','1mm','RightMargin:5mm','38mm','生产时间');
				LODOP.SET_PRINT_STYLE("FontSize",9);
				LODOP.ADD_PRINT_TEXT('18mm','1mm','RightMargin:5mm','38mm',data.dqsj);
				LODOP.SET_PRINT_STYLE("FontSize",9);
				LODOP.ADD_PRINT_TEXT('28mm','1mm','RightMargin:5mm','38mm','过期时间');
				LODOP.SET_PRINT_STYLE("FontSize",9);
				LODOP.ADD_PRINT_TEXT('32mm','1mm','RightMargin:5mm','38mm',data.gqsj);
				LODOP.PREVIEW()
				// LODOP.PRINT()//直接打印
			},
		},
	}
</script>

<style>
</style>
